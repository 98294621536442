import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";

// config file
const TxPlans = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Tx Plan");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    const tableFields = [
      {
        header: 'SNo',
        field: 'sno'
      }, {
        header: 'Code',
        field: 'code'
      }, {
        header: 'Description',
        field: 'description',
        bodyStyle: { width: '400px', wordBreak: 'break-all', whiteSpace: 'pre-line', maxHeight: '100px', overflowY: 'auto', }
      }, {
        header: 'Fee',
        field: 'displayFee'
      }, {
        header: 'Category',
        field: 'category'
      }, 
    ];
    let data = [
      {
        type: 'expander',
        textAlign: "center",
        width: 20,
        field: "expander",
        label: "expander",
        // header: "Procedure Steps",
        expandField: 'procedureSteps',
        tableFields: tableFields,
        show: true,
        mobile: true,
        "textAlign": "center",
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 40,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search",
        displayInSettings: true,
      },
      // {
      //   name: "hospitalName",
      //   type: "relateAutoComplete",
      //   placeholder: "Practice Name",
      //   label: "Practice Name",
      //   width: "150px",
      //   addFormOrder: 6,
      //   editFormOrder: 6,
      //   header: "Practice Name",
      //   derivedValue: "hospitalName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "hospitalName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   searchField: "hospitalName",
      //   populteFields: [],
      //   displayFields: [],
      //   controllerName: "hospitals",
      //   searchApi: "hospitals",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   filter: false,
      //   field: "hospitalId",
      //   fieldType: "relateAutoComplete",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      // },
      {
        name: "heading",
        type: "uniqueField",
        placeholder: "Heading",
        label: "Heading",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Heading",
        derivedValue: "heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "heading",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "heading",
        filter: true,
        showOrHideFields: [],
        fieldName: "heading",
        mobile: true,
        displayInSettings: true,
      }, {
        name: "note",
        type: "textarea",
        placeholder: "Note",
        label: "Note",
        width: "100px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Note",
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "note",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        // "actions": [],
        // "actionsNumber": [],
        id: "created",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.monthDateFormat,
        textAlign: "Center",
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true
      },
      {
        "name": "updated",
        "type": "date",
        "placeholder": "Updated",
        "label": "Updated",
        "width": 90,
        "header": "Updated",
        "derivedValue": "updated=undefined",
        // "actions": [],
        // "actionsNumber": [],
        "id": "updated",
        "displayinaddForm": "false",
        "displayineditForm": "false",
        "displayinlist": "true",
        "globalSearchField": "false",
        "controllerId": null,
        "fieldType": "Date",
        "dateFormat": config.monthDateFormat,
        "textAlign": "Center",
        "show": true,
        "field": "updated",
        "mobile": true,
        "displayInSettings": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 60,
        "fieldType": "Actions",
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "sortable": false,
        displayInSettings: true,
      }
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];

    // The Procedure Steps table Fields to Display 
    const procedureStepsTableFields = [
      { field: 'code', header: 'Code', filter: true },
      { field: 'description', filter: true, header: 'Description', },
      { field: 'category', header: 'Category', },
      { field: 'displayFee', header: 'Fee', },
    ]
    return [
      {
        name: "heading",
        type: "text",
        placeholder: "TX Plan",
        label: "TX Plan",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "TX Plan",
        derivedValue: "heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "heading",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "heading",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customValidateName: 'Tx Plan'
      },
      {
        name: "note",
        type: "textarea",
        placeholder: "Note",
        label: "Note",
        width: "200px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: 'procedureSteps',
        type: 'tableItemsSelector',
        label: 'Procedures',
        tableFields: procedureStepsTableFields,//TableFields to display
        searchApi: apiCalls.procedures,//Api call for the Procedures
        show: true,
        hasDependency: true,
        fieldForKey: "hospitalId",
        hasDependencyField: "hospitalId",
        isAddFormHidden: false,
        isEditFormHidden: false,
      }
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "HospitalI d",
        label: "Hospital Id",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Hospital Id",
        derivedValue: "hospitalId=hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: "Hospitals",
        searchField: "hospitalId",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["hospitalId", "hospitalName"],
        controllerName: "Hospitals",
        searchApi: "hospitals",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "hospitalId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Hospitals_hospitalId",
        type: "text",
        placeholder: "Hospitals_hospitalId",
        label: "Hospitals_hospitalId",
        header: "Hospitals hospitalId",
        width: 110,
        parentField: "hospitalId",
        id: "Hospitals_hospitalId",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "hospitalId",
        show: true,
        field: "Hospitals_hospitalId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Hospitals_hospitalName",
        type: "text",
        placeholder: "Hospitals_hospitalName",
        label: "Hospitals_hospitalName",
        header: "Hospitals hospitalName",
        width: 110,
        parentField: "hospitalId",
        id: "Hospitals_hospitalName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "hospitalName",
        show: true,
        field: "Hospitals_hospitalName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "heading",
        type: "text",
        placeholder: "Heading",
        label: "Heading",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Heading",
        derivedValue: "heading=heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "heading",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "heading",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "note",
        type: "text",
        placeholder: "Note",
        label: "Note",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Note",
        derivedValue: "note=note=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "note",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.txPlans}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];
  const excludeKeysForExport = ["procedureSteps"];
  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          PrintRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='TxPlans'
          // apiUrl={apiCalls.TxPlans}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.txplans}
          globalSearch={"Heading/Note"}
          displayName="Tx Plan"
          type="Tx Plan"
          routeTo={apiCalls.txPlans}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.txPlans}
          apiUrl={apiCalls.txPlans}
          selectedId={params.id}
          excludeKeysForExport={excludeKeysForExport}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="txPlans"
          apiUrl={apiCalls.txPlans}
        />
      ) : null}
    </span>
  );
};

export default TxPlans;
