import React, { useEffect, useState, useRef, useContext } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';


import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';

import { GlobalContext } from "../../../App/App";
import dateFormats from "../../../UI/FormatDate/formatDate";

import '../../../../scss/component/PerioCharts.scss';
import formatDate from "../../../UI/FormatDate/formatDate";

const PerioCharts = (props) => {
  const [rolePermission, setRolePermission] = useState();

  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [selectedTeeths, setSelectedTeeths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allPerioChartsData, setAllPerioChartsData] = useState([]);

  const context = useContext(GlobalContext);

  let dataTableRef = useRef(null);
  const params = useParams();

  useEffect(() => {

    reset();
    let screenPermissions = RolePermissions.screenPermissions("Perio Charts");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    if (!context.patientData) {
      showToasterMessage('Please select a patient first.', 'warning');
    } else {
      setValue("patientId", context.patientData);
      getDataFromServer();
    }
  }, [context.patientData]);

  let { handleSubmit, register, reset, setValue, getValues, setError, formState: { errors }, control, watch } = useForm();

  const missingTooth = watch("missingTooth") ? watch("missingTooth") : {};
  const perioChartId = watch("_id");

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];
  const teethArrT = Array.from({ length: 16 }, (_, index) => index + 1);
  const teethArrB = Array.from({ length: 16 }, (_, index) => 32 - index);
  const teethInputfieldsArr = Array.from({ length: 3 });


  const getDataFromServer = () => {
    let filterCriteria = { direction: 'desc', sortfield: 'created', criteria: [{ key: "patientId", value: context.patientData._id, type: "eq" }] };
    let url = apiCalls.perioCharts + `?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url).then(async (response) => {
      let data = { patientId: context.patientData, created: formatDate.formatDate("UTCTimeNow"), chartData: {} };
      let allData = await response && response[apiCalls.perioCharts] ? response[apiCalls.perioCharts] : [];
      if (allData?.length > 0) {
        data = await { ...allData[0] };
        data.allPerioChartsData = await allData.shift();
      }
      delete data._v;
      setAllPerioChartsData(allData);
      setSelectedTeeths([]);
      reset(data);
    })
  }


  /**
   * 
   * @param {Object} data 
   */
  const onSubmit = (data) => {//Save the data to the server
    const method = data._id ? "PUT" : "POST";
    const url = data._id ? apiCalls.perioCharts + `/${data._id}` : apiCalls.perioCharts;

    fetchMethodRequest(method, url, data).then((response) => {
      if (response && response.respCode) {
        getDataFromServer();
        showToasterMessage(response.respMessage, 'success');
      } else if (response && response.errorMessage) {
        showToasterMessage(response.errorMessage, 'error');
      }
    });
  }


  /**
   * 
   * @param {String} fieldName 
   */
  const focusNextInutField = (fieldName) => {//Focus the Nexted input field
    let [_, prefix, teethNumber, viewType, index] = fieldName.match(/^(.*?)(\d+)([A-Z])\[(\d+)\]$/);
    teethNumber = parseInt(teethNumber, 10);
    index = parseInt(index, 10);
    if (viewType === "F" && index == 2 && [16, 17].includes(teethNumber)) {
      viewType = "L";
      index = 3;
    } else if (viewType === "L" && index == 0 && teethNumber == 1) {
      viewType = "F";
      teethNumber = 33;
      index = 2;
    }

    if (viewType === "F" && teethNumber <= 16) {
      if (index == 2) {
        teethNumber++;
        index = 0;
      } else {
        index++;
      }

    } else if (viewType === "L" && teethNumber <= 16) {
      if (index == 0) {
        teethNumber--;
        index = 2
      } else {
        index--;
      }
    } else if (viewType === "F" && teethNumber >= 16) {
      if (index == 2) {
        teethNumber--;
        index = 0;
      } else {
        index++;
      }
    } else if (viewType === "L" && teethNumber >= 16) {
      if (index == 0) {
        teethNumber++;
        index = 2;
      } else {
        index--;
      }
    }

    // Focus the next input field
    const name = `${prefix}${teethNumber}${viewType}[${index}]`;
    if (missingTooth[teethNumber]) {
      focusNextInutField(name)
    }
    const inputField = document.querySelector(`[name="${name}"]`);
    if (inputField) {
      inputField.focus();
      // Move the caret to the end of the value
      const valueLength = inputField.value.length;
      inputField.setSelectionRange(valueLength, valueLength)
    } else {
      document.querySelector(`[name="${fieldName}"]`).blur();
    }
  }


  /**
   * 
   * @param {Object} param0 
   * @param {Object} item 
   * @returns 
   */
  const getInputField = ({ field, fieldState }, item) => {//Render the Input field 

    const onChange = async (e) => {
      let value = e.value !== null ? await parseInt(e.value.toString().slice(-1), 10) : undefined;
      field.onChange(value);
      document.querySelector(`[name="${field.name}"]`).value = value;
      if (value !== undefined) {
        focusNextInutField(field.name);
      }
    }

    return <InputNumber
      className={field.value > 3 ? "red" : ""}
      id={field.name}
      {...field}
      disabled={item.disabled}
      onChange={onChange}
      min="0"
      size={1}
    />
  }


  /**
   * 
   * @param {Object} item 
   * @param {number} index 
   * @returns 
   */
  const getTeethInputField = (item, index) => {//render the input field controller

    const name = `${item.parentInputKey}chartData.${item.teethNumber}${item.view}[${index}]`;
    item.disabled = missingTooth[item.teethNumber] ? true : false;
    return <Controller
      name={name}
      control={control}
      render={(field) => getInputField(field, item)}
    />
  }


  /**
   * 
   * @param {Object} data 
   * @returns 
   */
  const getTeethInputFields = (data) => {//render the three input fields for a Teeth
    return (
      <div className="teeth-container">
        {teethInputfieldsArr.map((_, index) => getTeethInputField(data, index))}
      </div>
    );
  }


  /**
   * 
   * @param {Object} data 
   * @returns 
   */
  const getRowColum = (data) => {//display the row of input fields

    if (data.view === "num") {//Display the Number field
      const className = selectedTeeths.includes(data.teethNumber) ? "teeth-number-selected" : " ";
      return <div onClick={() => onClickTeeth(data.teethNumber)} className="w-100 d-flex justify-content-center">
        <div key={data.teethNumber} className={className} >{data.teethNumber}</div>
      </div>;
    } else if (["F", "L"].includes(data.view)) {//Display the Input fields
      return getTeethInputFields(data)
    }
    return;
  }


  /**
   * 
   * @param {Number} teethNumber 
   * @param {String} view 
   * @param {String} parentInputKey 
   * @returns 
   */
  const getRowColumns = (teethNumber, view, parentInputKey) => {
    return (
      <div key={teethNumber} className={"teeth-input-card" + (missingTooth?.[teethNumber] ? " teeth-missed" : "")}>
        {getRowColum({ teethNumber, view, parentInputKey })}
      </div>
    );
  }


  /**
   * 
   * @param {string} view 
   * @param {string} key 
   * @returns 
   */
  const getHeader = (view, key) => {

    if (view !== "num") {
      const date = getValues(key + "created");
      if (["F", "L"].includes(view) && date) {
        return formatDate.formatDate(date, config.dateFormat);
      }
      return view;
    }
    return;;
  }


  /**
   * 
   * @param {Array} teethArr 
   * @param {string} view 
   * @returns 
   */
  const getTeethRow = (teethArr, view) => {
    let arr = [""]
    // if (["F", "L"].includes(view)) {

    // } else {
    //   arr.push("")
    // }

    // return arr.map((key, i) => <div className={(["num", "F", "L", ""].includes(view) ? "teeth-display-row-inputs " : "teeth-display-row ") + view}>
    //   <div className="teeth-input-card-header">{getHeader(view, key)}</div>
    //   {teethArr.map((teethNumber) => getRowColumns(teethNumber, view, key))}
    // </div>)
    return <div className={(["num", "F", "L", ""].includes(view) ? "teeth-display-row-inputs " : "teeth-display-row ") + view}>
      <div className="teeth-input-card-header">{getHeader(view, "")}</div>
      {teethArr.map((teethNumber) => getRowColumns(teethNumber, view, ""))}
    </div>
  }


  /**
   * 
   * @param {Array} teethArr 
   * @param {String} teethType 
   * @returns 
   */
  const getTeethInputs = (teethArr, teethType) => {
    // let arr = ["", "F", "num", "L", ""];
    let arr = ["", "F", "MGJ", "Ging Marg", "auto CAL", "Furc", "Mobility", "num", "Furc", "auto CAL", "Ging Marg", "L", ""];
    if (teethType === "B") {
      arr.reverse();
    }
    return <div className={"teeth-input-fields"}>
      {arr.map(type => getTeethRow(teethArr, type))}
    </div>
  }


  /**
   * 
   * @param {number} teethNumber 
   */
  async function onClickTeeth(teethNumber) {  //onclicking on the teeth number
    setSelectedTeeths(preSelectedTeeth => preSelectedTeeth.includes(teethNumber) ? preSelectedTeeth.filter(t => t !== teethNumber) : [...preSelectedTeeth, teethNumber]);
  }


  /**
   * clicking on the Missing Teeth
   */
  const onClickMissing = () => {
    let missingTooth = getValues('missingTooth') ? getValues('missingTooth') : {};
    selectedTeeths.forEach(tooth => missingTooth[tooth] = true)
    setValue("missingTooth", missingTooth);
    setSelectedTeeths([]);

  }


  /**
   * clicked on the Add new Perio chart
   */
  const onClickAdd = () => {
    reset({ patientId: context.patientData, created: formatDate.formatDate("UTCTimeNow"), chartData: {}, missingTooth });
  }


  /**
   * 
   * @returns render all perio charts data
   */
  const getPerioCharts = () => {
    return <form onSubmit={handleSubmit(onSubmit)} className="row align-items-center">

      <div className="col-3 d-flex justify-content-between">
        <Button size="small" type="submit">{perioChartId ? "Update" : "Save"}</Button>
        <Button size="small" type="button" onClick={onClickMissing}>Missing</Button>
        <Button icon="pi pi-refresh" size="small" type="button" onClick={getDataFromServer}></Button>
        <Button size="small" type="button" disabled={!perioChartId} onClick={onClickAdd}>Add New</Button>
      </div>
      <div className="col-6 main-input-div">
        {getTeethInputs(teethArrT, "T")}
        {getTeethInputs(teethArrB, "B")}
      </div>
      <div className="col-3 boder"></div>
    </form>
  }


  return <div className="periocharts">
    <h3 className="heading">Perio charts</h3>
    {context.patientData && getPerioCharts()}
  </div>
};

export default PerioCharts;
