import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Insurence = (props) => {
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(window.location.href.includes("edit"));
  const [addSelectedRecord, setAddSelectedRecord] = useState(window.location.href.includes("create"));
  const [viewSelectedRecord, setViewSelectedRecord] = useState(window.location.href.includes("view"));

  const [stateOptions, setStateOptions] = useState();
  const frequencyOptions = [
    { label: 'Every # Years', value: 'EveryYears' },
    { label: '# Peer Benifit Year', value: 'PeerBenifitYear' },
    { label: 'Every # Months', value: 'EveryMonths' },
    { label: '# in Last 12 Months', value: 'inLast12Months' },

  ] 
  const planType = [
    { label: 'PPO', value: 'PPO' },
    { label: 'HMO', value: 'HMO' },
  ]
  const feeSchedule = [
    { label: 'None', value: 'None' },
    { label: 'HMO', value: 'HMO' },
  ]



  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Insurance");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    setStateData();
  }, []);


  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  const setStateData = async () => {
    let arr = [];
    if (localStorage.StatesOptions) {
      arr = await JSON.parse(localStorage.StatesOptions);
    } else {
      let a = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
        if (res.settings[0]?.states?.length) {
          arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
        }
      })
    }
    setStateOptions(arr);
  }
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeer",
        type: "text",
        placeholder: "Employeer",
        label: "Employeer",
        header: "Employer",
        width: 110,
        filter: true,
        id: "employeer",
        capitalizeTableText: true,
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        // fieldType: "Link",
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "employer",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "carrier",
        fieldType: "relateAutoComplete",
        placeholder: "Carrier",
        label: "carrier",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Carrier",
        derivedValue: "carrier=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "carrier",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        searchField:'insuranceCompany',
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "carrier",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "number",
        placeholder: "phone",
        label: "phone",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Phone",
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: " address:",
        type: "text",
        placeholder: " address:",
        label: " address",
        header: ' Address',
        derivedValue: " address:=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: " address:",
        displayinlist: "true",
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        field: 'address',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "cityense number",
        label: "city",
        header: "City",
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "stateense number",
        label: "state",
        header: "State",
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "zipCode",
        label: "zipCode",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Zip Code",
        derivedValue: "zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "groupNumber",
        type: "text",
        placeholder: "groupNumber",
        label: "groupNumber",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Group#",
        derivedValue: "groupNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "groupNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "groupNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,

      },
      {
        name: "groupName",
        type: "text",
        placeholder: "groupName",
        label: "groupName",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Group Name",
        derivedValue: "groupName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "groupName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "groupName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "noOfElements",
        type: "number",
        placeholder: "noOfElements",
        label: "noOfElements",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "noE",
        derivedValue: "noOfElements=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "noOfElements",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "noOfElements",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "electronicId",
        type: "text",
        placeholder: "electId",
        label: "electId",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "ElectId",
        derivedValue: "electronicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "electronicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "electronicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "subscriberId",
        type: "text",
        placeholder: "sub",
        label: "sub",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Subs",
        derivedValue: "subscriberId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "subscriberId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "subscriberId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];

    data.push({
      "show": true,
      "textAlign": "center",
      "width": 60,
      "fieldType": "Actions",
      "field": "Actions",
      "header": "Actions",
      "filter": false,
      "sortable": false,
      displayInSettings: true,
    })
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "employer",
        type: "text",
        placeholder: "Employer",
        label: "Employer",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "employer=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "employer",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      // {
      //   name: "carrier",
      //   type: "autoComplete",
      //   placeholder: "Carrier",
      //   label: "Carrier",
      //   width: "130px",
      //   addFormOrder: 1,
      //   editFormOrder: 1,
      //   derivedValue: "carrier=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   isClickable: false,
      //   filter: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "carrier",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "true",
      //   required: true,
      //   displayOptionsInActions: false,
      //   globalSearchField: "true",
      //   controllerId: null,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   fieldType: "Link",
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      // },

      {
        name: "carrier",
        type: "relateAutoComplete",
        placeholder: "Carrier",
        label: "Carrier",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Carrier",
        derivedValue: "carrier=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "carrier",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "insuranceCompany",
        fieldType: "relateAutoComplete",
        populateValue: "carrier",
        populateField: "abbrevation",
        controllerName: "carrier",
        searchApi: apiCalls.carrier,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "carrier",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },

      {
        name: "phone",
        type: "number",
        placeholder: "Phone",
        label: "Phone",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        displayinregisterForm: "true",
        disabled: false,
        show: true,
        globalSearchField: "true",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        required: false,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "200px",
        // addFormOrder: 6,
        // editFormOrder: 6,
        header: "Address",
        derivedValue: "Address=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "200px",
        // addFormOrder: 6,
        // editFormOrder: 6,
        header: "Address2",
        derivedValue: "Address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        // addFormOrder: 7,
        // editFormOrder: 7,
        header: "City",
        derivedValue: "City=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: 'col-lg-2 col-md-2',
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "state",
        type: "dropDown",
        placeholder: "State",
        label: "State",
        width: "130px",
        header: "State",
        derivedValue: "State=undefined",
        capitalizeTableText: false,
        id: "state",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        customWidthClass: 'col-lg-1 col-md-2',
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "ZipCode",
        label: "ZipCode",
        width: "120px",
        // addFormOrder: 9,
        // editFormOrder: 9,
        header: "ZipCode",
        derivedValue: "ZipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        customWidthClass: 'col-lg-1 col-md-2',
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "fax",
        type: "text",
        placeholder: "Fax",
        label: "Fax",
        width: "200px",
        // addFormOrder: 6,
        // editFormOrder: 6,
        header: "Fax",
        derivedValue: "Address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "fax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "fax",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },

      {
        name: "electronicId",
        type: "text",
        placeholder: "ElectronicId",
        label: "ElectronicId",
        width: "130px",
        header: "electronicId",
        derivedValue: "electronicId=undefined",
        capitalizeTableText: false,
        id: "electronicId",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        // options:'',
        itemTemplate: true,
        filter: true,
        field: "electronicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "sendElectronically",
        type: "dropDown",
        placeholder: "Send Electronically",
        label: "Send Electronically",
        width: "130px",
        header: "Send Electronically",
        derivedValue: "sendElectronically=undefined",
        capitalizeTableText: false,
        id: "sendElectronically",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        // options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "sendElectronically",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "groupName",
        type: "text",
        placeholder: "Group Name",
        label: "Group Name",
        width: "200px",
        // addFormOrder: 6,
        // editFormOrder: 6,
        header: "groupName",
        derivedValue: "groupName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "groupName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "groupName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "groupNumber",
        type: "text",
        placeholder: "Group Number",
        label: "Group Number",
        width: "200px",
        // addFormOrder: 6,
        // editFormOrder: 6,
        header: "Group Number",
        derivedValue: "groupNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "groupNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "groupNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "otherSubscribers",
        type: "dropDown",
        placeholder: "Other Subscribers",
        label: "Other Subscribers",
        width: "130px",
        header: "Send Electronically",
        derivedValue: "otherSubscribers=undefined",
        capitalizeTableText: false,
        id: "otherSubscribers",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        // options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "otherSubscribers",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "planType",
        type: "dropDown",
        placeholder: "Plan Type",
        label: "Plan Type",
        width: "130px",
        header: "Plan Type",
        capitalizeTableText: false,
        id: "planType",
        required: true,
        optionLabel:'label',
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true, 
        options: planType,
        filter: true,
        field: "planType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },

      {
        name: "feeSchedule",
        type: "dropDown",
        placeholder: "Fee Schedule",
        label: "Fee Schedule",
        width: "130px",
        header: "Fee Schedule",
        capitalizeTableText: false,
        id: "feeSchedule",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true, 
        options: feeSchedule,
        filter: true,
        field: "feeSchedule",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },

      {
        name: "planNote",
        type: "textarea",
        placeholder: "Note",
        label: "Plan Note",
        width: "130px",
        header: "Plan Note",
        derivedValue: "planNote=undefined",
        capitalizeTableText: false,
        id: "planNote",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "planNote",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },

      {
        displayInSubscriberInfo:true,
        name: "subscriberId",
        type: "text",
        placeholder: "Subscriber Id",
        label: "Subscriber Id",
        width: "130px",
        header: "Subscriber Id",
        derivedValue: "subscriberId=undefined",
        capitalizeTableText: false,
        id: "subscriberId",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "subscriberId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        displayInSubscriberInfo:true,
        name: "fromDate",
        type: "date",
        placeholder: "From Date",
        label: "from Date",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "fromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        // customWidthClass: 'col-lg-4 col-md-6',
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "MM-DD-YYYY",
        fieldType: "Date",
        ageCaluclationField: 'age',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        displayInSubscriberInfo:true,
        name: "toDate",
        type: "date",
        placeholder: "To Date",
        label: "To Date",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "toDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        // customWidthClass: 'col-lg-4 col-md-6',
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "MM-DD-YYYY",
        fieldType: "Date",
        ageCaluclationField: 'age',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        displayInSubscriberInfo:true,
        name: "note",
        type: "textarea",
        placeholder: "Note",
        label: "Plan Note",
        width: "130px",
        header: "Plan Note",
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        id: "note",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: 'col-lg-12 col-md-12',
        itemTemplate: true,
        filter: true,
        field: "note",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },

    ];
  };

  const getBenifitInfoFormFields = () =>{
    return[

      //get Simplified Check box Form Fields

      {
        name: "calender",
        type: "checkbox",
        placeholder: "Calender",
        label: "Calender",
        derivedValue: "calender=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "calender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: true,
        mobile: true,
        fieldType: "dropDown",
        globalSearchField: "true",
        required: false,
        displayInSettings: true,
        addFormOrder: 7,
        editFormOrder: 7,
        isAddFormHidden: false,
        isEditFormHidden: false,
        displayinSimplified:true
      },
      {
        name: "month",
        type: "checkbox",
        placeholder: "Month",
        label: "Month",
        derivedValue: "month=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "month",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: true,
        mobile: true,
        fieldType: "dropDown",
        globalSearchField: "true",
        required: false,
        displayInSettings: true,
        addFormOrder: 7,
        editFormOrder: 7,
        isAddFormHidden: false,
        isEditFormHidden: false,
        displayinSimplified:true
      },

      //get Annul Max form Fields

      {
        name: "annualMaxIndividual",
        type: "text",
        placeholder: "Individual",
        label: "Annual Max",
        width: "130px",
        header: "Annual Max",
        derivedValue: "annualMaxIndividual=undefined",
        capitalizeTableText: false,
        id: "annualMaxIndividual",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "annualMaxIndividual",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false
      },
      {
        name: "annualMaxFamily",
        type: "text",
        placeholder: "Family",
        label: "Annual Max",
        width: "130px",
        header: "Annual Max",
        derivedValue: "annualMaxFamily=undefined",
        capitalizeTableText: false,
        id: "annualMaxFamily",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "annualMaxFamily",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidthClass: 'col-md-3 mt-4',
        disableLabel:true
      },
      {
        name: "generalDeductibleIndividual",
        type: "text",
        placeholder: "Individual",
        label: "General Deductible",
        width: "130px",
        header: "General Deductible",
        derivedValue: "generalDeductibleIndividual=undefined",
        capitalizeTableText: false,
        id: "generalDeductibleIndividual",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "generalDeductibleIndividual",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidthClass: 'col-md-3',
        disableLabel:false
      },
      {
        name: "generalDeductibleFamily",
        type: "text",
        placeholder: "Family",
        label: "General Deductible",
        width: "130px",
        header: "General Deductible",
        derivedValue: "generalDeductibleFamily=undefined",
        capitalizeTableText: false,
        id: "generalDeductibleFamily",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",          
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "generalDeductibleFamily",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidthClass: 'col-md-3 mt-4',
        disableLabel:true
      },

      {
        name: "fluoridethroughAge",
        type: "text",
        placeholder: "Fluoride Through Age",
        label: "Fluoride Through Age",
        width: "130px",
        header: "Fluoride Through Age",
        derivedValue: "fluoridethroughAge=undefined",
        capitalizeTableText: false,
        id: "fluoridethroughAge",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",          
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "fluoridethroughAge",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "sealantsthroughAge",
        type: "text",
        placeholder: "Sealants Through Age",
        label: "Sealants Through Age",
        width: "130px",
        header: "Sealants Through Age",
        derivedValue: "sealantsthroughAge=undefined",
        capitalizeTableText: false,
        id: "sealantsthroughAge",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",          
        show: true,
        options: frequencyOptions,
        filter: true,
        field: "sealantsthroughAge",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },

      //Frequency Form Fields 
      {
        name: "bws",
        type: "text",
        placeholder: "BWs",
        label: "BWs",
        width: "130px",
        header: "Annual Max",
        derivedValue: "bws=undefined",
        capitalizeTableText: false,
        id: "bws",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2 padding_right_0",
        itemTemplate: true,
        filter: true,
        field: "bws",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInFrequency:true
      },
      {
        name: "bwsType",
        type: "dropDown",
        placeholder: "Every # Years",
        label: "State",
        width: "130px",
        header: "State",
        derivedValue: "bwsType=undefined",
        capitalizeTableText: false,
        id: "bwsType",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: frequencyOptions,
        filter: true,
        customWidthClass: 'col-md-4',
        field: "bwsType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        displayInFrequency:true,
        disableLabel:true
      },
      {
        name: "fmx",
        type: "text",
        placeholder: "FMX",
        label: "Pano/FMX",
        width: "130px",
        header: "Annual Max",
        derivedValue: "fmx=undefined",
        capitalizeTableText: false,
        id: "fmx",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2 padding_right_0",
        itemTemplate: true,
        filter: true,
        field: "fmx",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInFrequency:true
      },
      {
        name: "fmxType",
        type: "dropDown",
        placeholder: "Every # Years",
        label: "State",
        width: "130px",
        header: "State",
        derivedValue: "fmxType=undefined",
        capitalizeTableText: false,
        id: "fmxType",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: frequencyOptions,
        filter: true,
        customWidthClass: 'col-md-4',
        field: "fmxType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        displayInFrequency:true,
        disableLabel:true
      },
      {
        name: "exams",
        type: "text",
        placeholder: "Exams",
        label: "Exams",
        width: "130px",
        header: "Annual Max",
        derivedValue: "exams=undefined",
        capitalizeTableText: false,
        id: "exams",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2 padding_right_0",
        itemTemplate: true,
        filter: true,
        field: "exams",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInFrequency:true
      },
      {
        name: "examsType",
        type: "dropDown",
        placeholder: "Every # Years",
        label: "State",
        width: "130px",
        header: "State",
        derivedValue: "examsType=undefined",
        capitalizeTableText: false,
        id: "examsType",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: frequencyOptions,
        filter: true,
        customWidthClass: 'col-md-4',
        field: "examsType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        displayInFrequency:true,
        disableLabel:true
      },

      //Otho Form Fields
      {
        name: "lifetimeMax",
        displayInOrtho:true,
        type: "text",
        placeholder: "Life Time Max",
        label: " Life Time Max",
        width: "130px",
        header: "Annual Max",
        derivedValue: " lifetimeMax=undefined",
        capitalizeTableText: false,
        id: " lifetimeMax",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: " lifetimeMax",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false
      },
      {
        name: "orthoPercentage",
        type: "text",
        placeholder: " Percentage",
        label: " Percentage",
        width: "130px",
        header: "Annual Max",
        derivedValue: " percentage=undefined",
        capitalizeTableText: false,
        id: " percentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: " percentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInOrtho:true
      },
      {
        name: "orthothroughAge",
        type: "text",
        placeholder: "Ortho ThroughAge",
        label: "Ortho ThroughAge",
        width: "130px",
        header: "Annual Max",
        derivedValue: "orthothroughAge=undefined",
        capitalizeTableText: false,
        id: "orthothroughAge",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        field: "orthothroughAge",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInOrtho:true
      },

      //Categorys Form Fields

      //Diagnostic (includes-x-ray) Form Fields
      {
        name: "diagnosticPercentage",
        type: "text",
        placeholder: "%",
        label: "Diagnostic (includes-x-ray)",
        width: "130px",
        header: "Annual Max",
        derivedValue: "diagnosticPercentage=undefined",
        capitalizeTableText: false,
        id: "diagnosticPercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "diagnosticPercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },
      {
        name: "diagnosticIndividual",
        type: "text",
        placeholder: "Individual",
        label: "",
        width: "130px",
        header: "Annual Max",
        derivedValue: "diagnosticIndividual=undefined",
        capitalizeTableText: false,
        id: "diagnosticIndividual",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "diagnosticIndividual",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },
      {
        name: "diagnosticFamily",
        type: "text",
        placeholder: "Family",
        label: "",
        width: "130px",
        header: "Annual Max",
        derivedValue: "diagnosticFamily=undefined",
        capitalizeTableText: false,
        id: "diagnosticFamily",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "diagnosticFamily",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },  

      //get xRayPercentage Form Fields
      {
        name: "xRayPercentage",
        type: "text",
        placeholder: "%",
        label: "X-Ray(if different)",
        width: "130px",
        header: "Annual Max",
        derivedValue: "xRayPercentage=undefined",
        capitalizeTableText: false,
        id: "xRayPercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "xRayPercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },
      {
        name: "xRayQuick",
        type: "text",
        placeholder: "Quick ",
        label: "",
        width: "130px",
        header: "Quick",
        derivedValue: "xRayQuick=undefined",
        capitalizeTableText: false,
        id: "xRayQuick",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-1",
        itemTemplate: true,
        filter: true,
        field: "xRayQuick",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      }, 
      {
        name: "xRayIndividual",
        type: "text",
        placeholder: "Individual",
        label: "",
        width: "130px",
        header: "Annual Max",
        derivedValue: "xRayIndividual=undefined",
        capitalizeTableText: false,
        id: "xRayIndividual",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-1",
        itemTemplate: true,
        filter: true,
        field: "xRayIndividual",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },
      {
        name: "xRayFamily",
        type: "text",
        placeholder: "Family",
        label: "",
        width: "130px",
        header: "Annual Max",
        derivedValue: "xRayFamily=undefined",
        capitalizeTableText: false,
        id: "xRayFamily",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "xRayFamily",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      }, 
      
      //Get Routine Preventive Form Fields 

      {
        name: "routinePercentage",
        type: "text",
        placeholder: "%",
        label: "Routine Preventive",
        width: "130px",
        header: "",
        derivedValue: "routinePercentage=undefined",
        capitalizeTableText: false,
        id: "routinePercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "routinePercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },
      {
        name: "routineIndividual",
        type: "text",
        placeholder: "Individual",
        label: "",
        width: "130px",
        header: "Annual Max",
        derivedValue: "routineIndividual=undefined",
        capitalizeTableText: false,
        id: "routineIndividual",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        options: stateOptions,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "routineIndividual",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },
      {
        name: "routineFamily",
        type: "text",
        placeholder: "Family",
        label: "",
        width: "130px",
        header: "Annual Max",
        derivedValue: "routineFamily=undefined",
        capitalizeTableText: false,
        id: "routineFamily",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "routineFamily",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },  

      //For Empty Space or Div
      {
        name:"space",
        type:'emptyField',
        displayInCategores:true 
      },

      // Restorative Form Fields
      {
        name: "restorativePercentage",
        type: "text",
        placeholder: "%",
        label: "Restorative",
        width: "130px",
        header: "Annual Max",
        derivedValue: "restorativePercentage=undefined",
        capitalizeTableText: false,
        id: "restorativePercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3 mt-5",
        itemTemplate: true,
        filter: true,
        field: "restorativePercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  
      {
        name: "restorativeMonth",
        type: "text",
        placeholder: "Month",
        label: "Month",
        width: "130px",
        header: "Annual Max",
        derivedValue: "restorativeMonth=undefined",
        capitalizeTableText: false,
        id: "restorativeMonth",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        itemTemplate: true,
        filter: true,
        field: "restorativeMonth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  

    
      {
        name: "perioPercentage",
        type: "text",
        placeholder: "%",
        label: "Perio",
        width: "130px",
        header: "Perio",
        derivedValue: "perioPercentage=undefined",
        capitalizeTableText: false,
        id: "perioPercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        itemTemplate: true,
        filter: true,
        field: "perioPercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  
      {
        name: "perioMonth",
        type: "text",
        placeholder: "Month",
        label: "Month",
        width: "130px",
        header: "Annual Max",
        derivedValue: "perioMonth=undefined",
        capitalizeTableText: false,
        id: "perioMonth",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        itemTemplate: true,
        filter: true,
        field: "perioMonth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  

      {
        name: "oralSurgeryPercentage",
        type: "text",
        placeholder: "%",
        label: "Oral Surgery",
        width: "130px",
        header: "Perio",
        derivedValue: "oralSurgeryPercentage=undefined",
        capitalizeTableText: false,
        id: "oralSurgeryPercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        itemTemplate: true,
        filter: true,
        field: "oralSurgeryPercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  
      {
        name: "oralSurgeryMonth",
        type: "text",
        placeholder: "Month",
        label: "Month",
        width: "130px",
        header: "Annual Max",
        derivedValue: "oralSurgeryMonth=undefined",
        capitalizeTableText: false,
        id: "oralSurgeryMonth",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        itemTemplate: true,
        filter: true,
        field: "oralSurgeryMonth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },  
      {
        name: "endoPercentage",
        type: "text",
        placeholder: "%",
        label: "Endo",
        width: "130px",
        header: "Annual Max",
        derivedValue: "endoPercentage=undefined",
        capitalizeTableText: false,
        id: "endoPercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "endoPercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  
      {
        name: "endoQuick",
        type: "text",
        placeholder: "Quick",
        label: "Month",
        width: "130px",
        header: "Annual Max",
        derivedValue: "endoQuick=undefined",
        capitalizeTableText: false,
        id: "endoQuick",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "endoQuick",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },  
      {
        name: "endoMonth",
        type: "text",
        placeholder: "Month",
        label: "Month",
        width: "130px",
        header: "Annual Max",
        derivedValue: "endoMonth=undefined",
        capitalizeTableText: false,
        id: "endoMonth",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "endoMonth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      },  
      {
        name: "prosthodonticPercentage",
        type: "text",
        placeholder: "%",
        label: "Prosthodontic",
        width: "130px",
        header: "Prosthodontic",
        derivedValue: "prosthodonticPercentage=undefined",
        capitalizeTableText: false,
        id: "prosthodonticPercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        itemTemplate: true,
        filter: true,
        field: "prosthodonticPercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  
      {
        name: "prosthodonticMonth",
        type: "text",
        placeholder: "Month",
        label: "Month",
        width: "130px",
        header: "Annual Max",
        derivedValue: "prosthodonticMonth=undefined",
        capitalizeTableText: false,
        id: "prosthodonticMonth",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-3",
        itemTemplate: true,
        filter: true,
        field: "prosthodonticMonth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      }, 
      {
        name: "crownsPercentage",
        type: "text",
        placeholder: "%",
        label: "Crowns",
        width: "130px",
        header: "Perio",
        derivedValue: "crownsPercentage=undefined",
        capitalizeTableText: false,
        id: "crownsPercentage",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "crownsPercentage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:false,
        displayInCategores:true  
      },  
      {
        name: "crownsQuick",
        type: "text",
        placeholder: "Quick",
        label: "Quick",
        width: "130px",
        header: "Quick",
        derivedValue: "crownsQuick=undefined",
        capitalizeTableText: false,
        id: "crownsQuick",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "crownsQuick",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      }, 
      {
        name: "crownsMonth",
        type: "text",
        placeholder: "Month",
        label: "Month",
        width: "130px",
        header: "Annual Max",
        derivedValue: "crownsMonth=undefined",
        capitalizeTableText: false,
        id: "crownsMonth",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        customWidthClass: "col-md-2",
        itemTemplate: true,
        filter: true,
        field: "crownsMonth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        disableLabel:true,
        displayInCategores:true  
      }, 



      
    ]
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];


  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          benifitInfoFormFields={getBenifitInfoFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          PrintRequired={isEditPermission}
          printRequried={true}
          actionsTypes={actionTypes}
          // customInsuranceFormModal={customInsuranceFormModal}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.insurances}
          globalSearch={"Employer"}
          displayName="Insurance"
          type="Insurance"
          routeTo={apiCalls.Insurence}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.Insurence.toLowerCase()}
          apiUrl={apiCalls.Insurence}
          selectedId={params.id}
        />
      }
    </span>
  );
};

export default Insurence;
