import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const AvailablePrompts = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Available Prompts");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "text",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Description",
        derivedValue: "description=description=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "left",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Type",
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Text",
            value: "Text",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Single Selection",
            value: "Single Selection",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Multi Selection",
            value: "Multi Selection",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
        ],
        filterElement: [
          {
            label: "Text",
            value: "Text",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Single Selection",
            value: "Single Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Multi Selection",
            value: "Multi Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        fieldType: "dropDown",
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "promptText",
        fieldName: "promptText",
        type: "text",
        placeholder: "Prompt Text",
        value: "",
        label: "Prompt Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Prompt Text",
        derivedValue: "promptText=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "promptText",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "left",
        show: true,
        field: "promptText",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "defaultText",
        fieldName: "defaultText",
        type: "textarea",
        placeholder: "Default Text",
        value: "",
        label: "Default Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Default Text",
        derivedValue: "defaultText=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "defaultText",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "left",
        show: true,
        field: "defaultText",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },


      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const typeOnChange = (data) => {
    const { value, getValue, setValue } = data;
    if (value === "Date") {
      setValue('defaultText', null)
    }
  }

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "description",
        fieldName: "description",
        type: "text",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "description=description=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Text",
            value: "Text",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Single Selection",
            value: "Single Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Date",
            value: "Date",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Multi Selection",
            value: "Multi Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        filterElement: [
          {
            label: "Text",
            value: "Text",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Single Selection",
            value: "Single Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Date",
            value: "Date",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Multi Selection",
            value: "Multi Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [{ "Multi Selection": ["defaultText"] }, { "Single Selection": ["defaultText"] }, { "Text": ["defaultText"] }],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        onChange: typeOnChange
      },
      {
        name: "promptText",
        fieldName: "promptText",
        type: "text",
        placeholder: "Prompt Text",
        value: "",
        label: "Prompt Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "promptText=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "promptText",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "defaultText",
        fieldName: "defaultText",
        type: "textarea",
        placeholder: "Default Text",
        value: "",
        label: "Default Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "defaultText=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "defaultText",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        validationMessageType: true,
      },

    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Type",
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Text",
            value: "Text",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Single Selection",
            value: "Single Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Multi Selection",
            value: "Multi Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        filterElement: [
          {
            label: "Text",
            value: "Text",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Single Selection",
            value: "Single Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Multi Selection",
            value: "Multi Selection",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "promptText",
        fieldName: "promptText",
        type: "text",
        placeholder: "Prompt Text",
        value: "",
        label: "Prompt Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Prompt Text",
        derivedValue: "promptText=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "promptText",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "promptText",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "defaultText",
        fieldName: "defaultText",
        type: "textarea",
        placeholder: "Default Text",
        value: "",
        label: "Default Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Default Text",
        derivedValue: "defaultText=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "defaultText",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "defaultText",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "text",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Description",
        derivedValue: "description=description=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.availablePrompts}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          // globalSearch={'Display Name/Email'}
          // type='AvailablePrompts'
          // apiUrl={apiCalls.AvailablePrompts}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.availablePrompts}
          globalSearch={
            "Description"
          }
          displayName="Available Prompts"
          type="Available Prompts"
          routeTo={'availablePrompts'}
          customResponseKey={"availableprompts"}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.availablePrompts?.toLowerCase()}
          apiUrl={apiCalls.availablePrompts}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="availablePrompts"
          apiUrl={apiCalls.availablePrompts}
        />
      ) : null}
    </span>
  );
};

export default AvailablePrompts;
